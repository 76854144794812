import { useAuth } from "./auth";
import { useRouter } from "next/router";
import Logo from "../components/app/logo";

const isBrowser = () => typeof window !== "undefined";

const ProtectedRoute = ({ children }) => {
  //Identify authenticated user
  const { user, loading } = useAuth();
  const router = useRouter();

  let unprotectedRoutes = ["/login"];

  /**
   * @let pathIsProtected Checks if path exists in the unprotectedRoutes routes array
   */
  let pathIsProtected = unprotectedRoutes.indexOf(router.pathname) === -1;

  if (isBrowser() && !user && !loading && pathIsProtected) {
    router.push({
      pathname: "/login",
      query: { previousUrl: router.pathname },
    });
  }

  if (isBrowser() && user && router.pathname === "/login") {
    router.push({
      pathname: "/",
    });
  }

  if (loading) {
    return (
      <div className="flex min-h-screen justify-center items-center">
        <div className={"w-44"}>
          <Logo />
        </div>
        {/*<div className="flex max-w-m rounded overflow-hidden shadow-lg my-2 text-white bg-gray-800">*/}
        {/*    <div className="flex flex-col items-center p-6">*/}
        {/*        <svg className="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">*/}
        {/*            <circle cx="170" cy="170" r="160" stroke="#3C4F79"/>*/}
        {/*            <circle cx="170" cy="170" r="135" stroke="#ffffff"/>*/}
        {/*            <circle cx="170" cy="170" r="110" stroke="#3C4F79"/>*/}
        {/*            <circle cx="170" cy="170" r="85" stroke="#ffffff"/>*/}
        {/*        </svg>*/}
        {/*        <p className="text-grey-darker mt-3 text-base">*/}
        {/*            Loading...*/}
        {/*        </p>*/}
        {/*    </div>*/}
        {/*</div>*/}
      </div>
    );
  }

  return children;
};

export default ProtectedRoute;

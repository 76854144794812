import axios from "axios";

export const AuthAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_AUTH_URL
});

const Axios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_URL
});


export let userToken = null;

// Axios.interceptors.request.use(
//   (config) => {
//     if (userToken) {
//       config.headers.authorization = `Bearer ${userToken}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export function setToken(token) {
  userToken = token;
}

export default Axios;

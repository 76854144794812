import { useState, useEffect, useContext, createContext } from "react";
import { initializeApp, getApps } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signOut as fSignOut,
} from "firebase/auth";
import { firebaseConfig } from "../config/firebase";
import { authenticateUserWithServer } from "../services/authService";
import { setToken } from "../services/axios";
import { setCookies } from "cookies-next";

const providers = {
  google: new GoogleAuthProvider(),
  facebook: new FacebookAuthProvider(),
  microsoft: new OAuthProvider("microsoft.com"),
};

// initialize auth context
const AuthContext = createContext(null);

// hook that we can use anywhere in the app
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

// firebase functions are defined here
const useAuthProvider = () => {
  let firebaseApp;
  if (!getApps().length) {
    firebaseApp = initializeApp(firebaseConfig);
  }
  const auth = getAuth(firebaseApp);

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userToken, setUserToken] = useState(null);
  const [accountData, setAccountData] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setLoading(false);
      } else {
        setUser(false);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth, user]);

  useEffect(() => {
    if (!userToken && user) {
      user.getIdToken().then(function (idToken) {
        authenticateUserWithServer(idToken)
          .then(({ data }) => {
            setUserToken(data.token);
            setToken(data.token);
            setCookies("mysky.user.token", data.token);
            fetchAccountData(data.token);
            //document.cookie = `mysky.user.token=${data.token}`;
          })
          .catch(() => {
            signOut();
            setUser(false);
            setLoading(false);
          });
      });
    }
  }, [userToken, user]);

  const fetchAccountData = async (token) => {
    try {
      const result = await fetch(
        `${process.env.NEXT_PUBLIC_APP_URL}/api/user/account`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await result.json();
      setAccountData(data);
      console.log(data);
    } catch {
      setAccountData(null);
    }
  };

  const signIn = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const signInWithGoogle = async () => {
    await signInWithPopup(auth, providers.google);
  };

  const signInWithFacebook = async () => {
    await signInWithPopup(auth, providers.facebook);
  };

  const signInWithMicrosoft = async () => {
    await signInWithPopup(auth, providers.microsoft);
  };

  const forgotPassword = async (email) => {
    await forgotPassword(email);
  };

  const signOut = async () => {
    await fSignOut(auth);
  };

  return {
    user,
    userToken,
    accountData,
    loading,
    providers,
    signIn,
    signOut,
    signInWithGoogle,
    signInWithMicrosoft,
    signInWithFacebook,
  };
};

export const firebaseConfig = {
    apiKey: "AIzaSyDXNZ0yCTIL3HP7TkDluPP1E-70Hipq5Xg",
    authDomain: "identity.skypbx.com",
    databaseURL: "https://announce-app.firebaseio.com",
    projectId: "announce-app",
    storageBucket: "announce-app.appspot.com",
    messagingSenderId: "20209551258",
    appId: "1:20209551258:web:1459e969f1654877ef5c23",
    measurementId: "G-WVWZMZWHKD"
};

import "../styles/globals.css";
import { AuthProvider } from "../lib/auth";
import { CartProvider } from "../lib/cart";
import ProtectedRoute from "../lib/protectedRoute";
import { AppProps } from "next/app";
import { DefaultSeo } from "next-seo";

function MyApp({ Component, pageProps }: AppProps) {
  // @ts-ignore
  const getLayout = Component.getLayout || ((page) => page);

  //SkyPBX Client Portal

  return (
    <AuthProvider>
      <CartProvider>
        <ProtectedRoute>
          <DefaultSeo
            titleTemplate={"%s - SkyPBX Client Portal"}
            defaultTitle={"SkyPBX Client Portal"}
            openGraph={{
              type: "website",
              locale: "en_US",
              url: "https://my.skypbx.com/",
              site_name: "SkyPBX Client Portal",
            }}
          />
          {getLayout(<Component {...pageProps} />)}
        </ProtectedRoute>
      </CartProvider>
    </AuthProvider>
  );
}

export default MyApp;

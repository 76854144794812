const Logo = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 269.78 61.36"
    >
      <defs>
        <linearGradient id="logo-gradient" x1="0%" y1="100%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: "#d13159" }} />
          <stop offset="25%" style={{ stopColor: "#a73e80" }} />
          <stop offset="50%" style={{ stopColor: "#6d4d8a" }} />
          <stop offset="75%" style={{ stopColor: "#3c4f79" }} />
          <stop offset="100%" style={{ stopColor: "#10385a" }} />
        </linearGradient>
      </defs>
      <path
        fill="url(#logo-gradient)"
        d="M63.51,28.99L51.48,10.05C42.23-4.52,26.84-3.08,17.11,13.28L4.26,34.9c-9.72,16.36-2.25,28.12,16.69,26.28
	l25.02-2.44C64.92,56.88,72.77,43.57,63.51,28.99z"
      />
      <path
        className="fill-white"
        d="M27.68,43.45c-0.9,0.59-1.95,0.76-2.93,0.56c-0.98-0.2-1.88-0.78-2.47-1.67c-0.59-0.9-0.76-1.95-0.56-2.93
	c0.2-0.98,0.78-1.88,1.67-2.47c0.9-0.59,1.95-0.76,2.93-0.56c0.98,0.2,1.88,0.78,2.47,1.67c0.59,0.9,0.76,1.95,0.56,2.93
	C29.15,41.96,28.58,42.86,27.68,43.45z"
      />
      <path
        className="fill-white"
        d="M38.77,44.62c-0.23,1.1-1.3,1.81-2.4,1.58c-1.1-0.23-1.81-1.3-1.58-2.4c1.34-6.51-2.87-12.9-9.38-14.24
	c-1.1-0.23-1.81-1.3-1.58-2.4c0.23-1.1,1.3-1.81,2.4-1.58C34.93,27.37,40.56,35.91,38.77,44.62z"
      />
      <path
        className="fill-white"
        d="M47.26,46.37c-0.23,1.1-1.3,1.81-2.4,1.58c-1.1-0.23-1.81-1.3-1.58-2.4c2.3-11.2-4.93-22.18-16.13-24.48
	c-1.1-0.23-1.81-1.3-1.58-2.4c0.23-1.1,1.3-1.81,2.4-1.58C41.36,19.84,50.02,32.98,47.26,46.37z"
      />
      <g>
        <path
          className="dark:fill-white fill-gray-700"
          d="M86.34,46.87v-4.91c3.17,1.23,6.42,1.84,9.76,1.84c2.76,0,4.76-0.32,6.01-0.97c1.24-0.65,1.87-1.86,1.87-3.63
		c0-1.13-0.26-2.02-0.79-2.68c-0.53-0.67-1.39-1.24-2.58-1.71c-1.19-0.48-2.98-1.02-5.37-1.64c-3.54-0.92-6.03-2.09-7.46-3.5
		c-1.43-1.41-2.15-3.36-2.15-5.85c0-2.93,1.07-5.2,3.22-6.82c2.15-1.62,5.28-2.43,9.41-2.43c1.81,0,3.55,0.13,5.24,0.38
		c1.69,0.26,3.06,0.57,4.12,0.95v4.91c-2.59-0.99-5.45-1.48-8.59-1.48c-2.59,0-4.53,0.34-5.83,1.02c-1.3,0.68-1.94,1.84-1.94,3.48
		c0,0.99,0.24,1.78,0.72,2.38c0.48,0.6,1.27,1.13,2.38,1.58c1.11,0.46,2.77,0.96,4.98,1.51c3.85,0.99,6.52,2.27,8.02,3.83
		c1.5,1.57,2.25,3.6,2.25,6.08c0,2.93-1.1,5.21-3.3,6.85s-5.43,2.45-9.69,2.45C92.35,48.5,88.93,47.96,86.34,46.87z"
        />
        <path
          className="dark:fill-white fill-gray-700"
          d="M115.01,13.54h5.57v19.93l10.17-9.71h6.39l-9.86,9.76l10.43,14.46h-5.88l-8.02-11.5l-3.22,3.07v8.43h-5.57
		V13.54z"
        />
        <path
          className="dark:fill-white fill-gray-700"
          d="M143.89,56.27v-4.14c0.95,0.34,1.99,0.51,3.12,0.51c1.02,0,1.86-0.16,2.5-0.49c0.65-0.32,1.21-0.83,1.69-1.51
		c0.48-0.68,1.04-1.67,1.69-2.96L142.1,23.77h5.78l7.77,17.99l7.87-17.99h5.72l-9.87,22.85c-1.46,3.48-2.99,6.07-4.57,7.79
		c-1.58,1.72-3.65,2.58-6.21,2.58C146.75,56.99,145.19,56.75,143.89,56.27z"
        />
        <path
          className="dark:fill-white fill-gray-700"
          d="M173.08,15.08h16.2c3.78,0,6.65,1.08,8.61,3.25c1.96,2.16,2.94,5,2.94,8.51c0,2.25-0.48,4.24-1.43,5.98
		c-0.95,1.74-2.33,3.09-4.11,4.06c-1.79,0.97-3.86,1.46-6.21,1.46h-7.77v9.66h-8.23V15.08z M187.24,32c1.7,0,3-0.47,3.88-1.41
		c0.89-0.94,1.33-2.21,1.33-3.81c0-1.74-0.41-3.07-1.23-3.99c-0.82-0.92-2.06-1.38-3.73-1.38h-6.18V32H187.24z"
        />
        <path
          className="dark:fill-white fill-gray-700"
          d="M204.05,15.08h17.38c3.27,0,5.76,0.78,7.46,2.33s2.56,3.77,2.56,6.67c0,1.67-0.32,3.11-0.97,4.32
		c-0.65,1.21-1.53,2.14-2.66,2.79c1.6,0.58,2.8,1.42,3.6,2.53c0.8,1.11,1.2,2.65,1.2,4.63c0,3.14-0.92,5.53-2.76,7.18
		c-1.84,1.65-4.48,2.48-7.92,2.48h-17.89V15.08z M218.98,28.47c2.73,0,4.09-1.23,4.09-3.68c0-1.29-0.34-2.25-1.02-2.86
		c-0.68-0.61-1.84-0.92-3.48-0.92h-6.29v7.46H218.98z M219.79,42.12c1.53,0,2.66-0.31,3.37-0.95c0.72-0.63,1.07-1.71,1.07-3.25
		c0-1.29-0.37-2.25-1.1-2.86c-0.73-0.61-1.95-0.92-3.65-0.92h-7.21v7.97H219.79z"
        />
        <path
          className="dark:fill-white fill-gray-700"
          d="M247.8,31.43l-11.65-16.36h9.81l6.8,9.81l6.85-9.81h9.81l-11.65,16.36l12.01,16.56h-9.92l-7.11-9.97
		l-7.05,9.97h-9.92L247.8,31.43z"
        />
      </g>
    </svg>
  );
};

export default Logo;

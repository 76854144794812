import {createContext, useContext, useState} from "react";
import {iProduct} from "./product";

const CartContext = createContext(null);

// Export hook to allow us to use cart functions throughout app
export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
    const cart = useCartProvider();
    return <CartContext.Provider value={cart}>{children}</CartContext.Provider>;
};

const useCartProvider = () => {
    const [cartItems, setCartItems] = useState([]);
    const [cartTotal, setCartTotal] = useState(0);

    const addProductToCart = (product: iProduct) => {
        let existing_item = cartItems.find(item => product.id === item.id);
        if(existing_item){
            let addItemIndex = cartItems.findIndex(item=> item.id === product.id);
            let newItems = [...cartItems];
            newItems[addItemIndex] ={...newItems[addItemIndex],quantity: ++newItems[addItemIndex].quantity};
            setCartItems(newItems);
            setCartTotal(cartTotal => cartTotal + product.price)
        } else {
            let added_product = {...product, quantity: 1};
            setCartItems(cartItems => [...cartItems, added_product]);
            setCartTotal(cartTotal => cartTotal + product.price)
        }
        // if(cartItems.some(x => x.id === product.id)) {
        //     setCartItems(
        //         cartItems.map((item, index) => {
        //             if(item.id === product.id){
        //                 item.quantity++;
        //             }
        //             return item;
        //         })
        //     )
        // } else {
        //     setCartItems(cartItems => [...cartItems, product]);
        // }
    }

    const removeProductFromCart = (product: iProduct, completely: boolean) => {

        if(completely){
            setCartItems(cartItems.filter(item => item.id !== product.id))
            setCartTotal(cartTotal - product.price);
        }

        const existing_item = cartItems.find(item => item.id === product.id)

        if(existing_item) {
            if(existing_item.quantity === 1){
                setCartItems(cartItems.filter(item => item.id !== product.id))
                setCartTotal(cartTotal - existing_item.price);
            } else {
                let removeItemIndex = cartItems.findIndex(item=> item.id === product.id);
                let newItems = [...cartItems];
                newItems[removeItemIndex] ={...newItems[removeItemIndex],quantity: --newItems[removeItemIndex].quantity};
                setCartItems(newItems);
                setCartTotal(cartTotal => cartTotal - product.price)
            }
        }
    }

    const isProductInCart = (product: iProduct) => {
        return cartItems.some(item => item.id === product.id)
    }

    const getProductQuanity = (product: iProduct) => {
        return cartItems.find(item => item.id === product.id).quantity
    }

    const getCartQuantity = () => {
        let total = 0;
        cartItems.forEach(item => {
            total = total + item.quantity;
        })
        return total
    }

    return {
        cartItems,
        cartTotal,
        getCartQuantity,
        addProductToCart,
        removeProductFromCart,
        isProductInCart,
        getProductQuanity
    }
}

import {AuthAxios} from "./axios";

export const authenticateUserWithServer = (idToken) => {
  return AuthAxios.post(
    "/token/generate",
    {
      token: idToken.toString(),
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
